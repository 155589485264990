import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { getJavaScriptResourceInfo } from './getJavaScriptResourceInfo.js'
import { logPerformanceMetrics } from './logPerformanceMetric.js'
import { logRumEvent } from './logRumEvent.js'
import { pageDone } from './unifiedShell.js'

const localLogging = localStorage.getItem('qsperftiming')

// 15% more nodes than before qualifies as a LCP event
const LCP_CHANGE_THRESHOLD = 1.15
// We typically see this with skeleton loaders being removed
const LCP_RESET_THRESHOLD = 0.5

let pageWasHiddenAtSomePoint = document.hidden

/**
 * @returns {any} - refForPerformanceMeasurements
 */
export function useLoadTime() {
  const refForPerformanceMeasurements = useRef()
  const refForBaseLocationComparison = useRef()
  const measurementTimeoutRef = useRef()
  const observerRef = useRef()
  const iframeAbortControllerRef = useRef()
  const iframesWeHaveMeasuredRef = useRef([])

  const { pathname } = useLocation()

  useEffect(() => {
    function clearNonIframeObservers() {
      clearTimeout(measurementTimeoutRef.current)
      observerRef.current?.disconnect()
    }
    function clearAll() {
      clearNonIframeObservers()
      iframeAbortControllerRef.current?.abort()
    }

    if (
      document.hidden ||
      refForPerformanceMeasurements.current == null ||
      (window.DD_RUM === undefined && !localLogging)
    ) {
      if (document.hidden) {
        pageWasHiddenAtSomePoint = true
      }

      clearAll()
      pageDone()

      return () => {}
    }

    const initialTimingCaptured = getInitialTimingCaptured()

    if (
      initialTimingCaptured &&
      // using startsWith because the secondary nav can add the subPath via a redirect + replace
      !pathname.startsWith(refForBaseLocationComparison.current)
    ) {
      setMarkForRouteChangeTiming()
    }

    refForBaseLocationComparison.current = pathname

    let hasPageDoneBeenCalled = initialTimingCaptured
    let numberOfChildrenInLastPageSizeIncease = 1
    let markHasBeenSetForThisMeasurementCycle = false
    let lastTimeoutDuration
    let javaScriptResourceInfo
    let currentSkeletonLoaders = new Set()

    function measureLoadTime(mutationList, observer) {
      if (refForPerformanceMeasurements.current == null) {
        return observer.disconnect()
      }

      const iframeChild =
        refForPerformanceMeasurements.current.querySelector('iframe')

      if (iframeChild) {
        if (iframesWeHaveMeasuredRef.current.includes(iframeChild.src)) {
          return clearNonIframeObservers()
        }

        if (iframeChild.src) {
          iframesWeHaveMeasuredRef.current.push(iframeChild.src)
        }
        iframeAbortControllerRef.current = new AbortController()

        const kaminoShim = iframeChild.src.includes('/qstile')

        if (kaminoShim) {
          waitForRedRockLcpMessage(
            initialTimingCaptured,
            iframeAbortControllerRef.current
          )

          return clearNonIframeObservers()
        }

        // for the proofing page, this is not as accurate,
        // almost every other iframe is a KaminoShim and is handled above
        if (
          pathname.includes('/proof/') ||
          pathname.includes('/proofing-workflow')
        ) {
          iframeChild.addEventListener(
            'load',
            () => {
              iframeAbortControllerRef.current?.abort()
              setMarkForPotentialLoadTime(initialTimingCaptured)
              logTiming(
                initialTimingCaptured,
                initialTimingCaptured ? undefined : getJavaScriptResourceInfo()
              )
            },
            { signal: iframeAbortControllerRef.current.signal }
          )

          return clearNonIframeObservers()
        }
      }

      iframeAbortControllerRef.current = null

      // custom algorithm to approximate the largest-contentful-paint metric found in Chrome
      // this allows us to track the metric more than once for non-initial page loads on route change
      const currentNumberOfChildren =
        refForPerformanceMeasurements.current.querySelectorAll('*').length

      let skeletonLoaderRemovedThisCycle

      for (const mutationRecord of mutationList) {
        const addedSkeletonLoader = getSkeletonLoaders(
          mutationRecord.addedNodes
        )
        const removedSkeletonLoader = getSkeletonLoaders(
          mutationRecord.removedNodes
        )

        if (removedSkeletonLoader.size > 0) {
          skeletonLoaderRemovedThisCycle = true
        }

        currentSkeletonLoaders = currentSkeletonLoaders
          .union(addedSkeletonLoader)
          .difference(removedSkeletonLoader)
      }

      const skeletonLoadersStillLoading =
        currentSkeletonLoaders.size === 0
          ? skeletonLoaderRemovedThisCycle
            ? false
            : undefined
          : true

      const { shouldMark, shouldReset, childCountIsSignificant } =
        shouldMarkOrReset(
          currentNumberOfChildren,
          numberOfChildrenInLastPageSizeIncease,
          skeletonLoadersStillLoading
        )

      let nextTimeoutDuration

      if (shouldMark) {
        numberOfChildrenInLastPageSizeIncease = currentNumberOfChildren

        nextTimeoutDuration = getMaxWaitTime(
          currentNumberOfChildren,
          lastTimeoutDuration
        )

        if (!initialTimingCaptured) {
          javaScriptResourceInfo = getJavaScriptResourceInfo()
        }

        // Note: page done can fire slightly earlier than our load time
        if (!hasPageDoneBeenCalled && childCountIsSignificant) {
          pageDone()
          hasPageDoneBeenCalled = true
        }

        setMarkForPotentialLoadTime(initialTimingCaptured)
        markHasBeenSetForThisMeasurementCycle = true
      } else if (shouldReset || lastTimeoutDuration == null) {
        numberOfChildrenInLastPageSizeIncease = currentNumberOfChildren
        markHasBeenSetForThisMeasurementCycle = false
        removeMarkForPotentialLoadTime(initialTimingCaptured)
        nextTimeoutDuration =
          currentSkeletonLoaders.size > 0
            ? MAX_WAIT_PERIOD_WITH_SKELETON_LOADER
            : MAX_WAIT_PERIOD
      }

      if (nextTimeoutDuration) {
        lastTimeoutDuration = nextTimeoutDuration
        clearTimeout(measurementTimeoutRef.current)

        measurementTimeoutRef.current = setTimeout(() => {
          observer?.disconnect()

          if (!markHasBeenSetForThisMeasurementCycle) {
            // we never had an increase, set now as the load time
            setMarkForPotentialLoadTime(initialTimingCaptured)
          }

          // Note: page done can fire later than our load time
          if (!hasPageDoneBeenCalled) {
            pageDone()
            hasPageDoneBeenCalled = true
          }

          logTiming(initialTimingCaptured, javaScriptResourceInfo)
        }, nextTimeoutDuration)
      }
    }

    observerRef.current = new MutationObserver(measureLoadTime)
    observerRef.current.observe(
      refForPerformanceMeasurements.current,
      mutationObserverConfig
    )

    return clearAll
  }, [pathname])

  useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        pageWasHiddenAtSomePoint = true
      }
      clearTimeout(measurementTimeoutRef.current)
      observerRef.current?.disconnect()
      iframeAbortControllerRef.current?.abort()
    })
  }, [])

  return refForPerformanceMeasurements
}

const mutationObserverConfig = { childList: true, subtree: true }

function waitForRedRockLcpMessage(
  initialTimingCaptured,
  iframeLargestContentfulPaintController
) {
  // RedRock has similar code to this hook that fires the qs-lcp message below
  window.addEventListener(
    'message',
    (msg) => {
      if (msg.data.source === 'qs-lcp') {
        setMarkForPotentialLoadTime(initialTimingCaptured)
        logTiming(
          initialTimingCaptured,
          initialTimingCaptured ? undefined : getJavaScriptResourceInfo()
        )

        iframeLargestContentfulPaintController.abort()
      }
    },
    { signal: iframeLargestContentfulPaintController.signal }
  )
}

function getInitialTimingCaptured() {
  return (
    // you can only get route change timings if hidden initially
    pageWasHiddenAtSomePoint ||
    performance.getEntriesByName('nwe_initial_load').length === 1
  )
}

function setMarkForRouteChangeTiming() {
  performance.clearMeasures('nwe_route_change')
  performance.clearMarks('nwe_route_change_start')
  performance.mark('nwe_route_change_start')
}

function setMarkForPotentialLoadTime(initialTimingCaptured) {
  removeMarkForPotentialLoadTime(initialTimingCaptured)

  if (initialTimingCaptured) {
    performance.mark('nwe_route_change_end')
  } else {
    performance.mark('nwe_initial_load')
  }
}

function removeMarkForPotentialLoadTime(initialTimingCaptured) {
  if (initialTimingCaptured) {
    performance.clearMarks('nwe_route_change_end')
  } else {
    performance.clearMarks('nwe_initial_load')
  }
}

function getLoadTime(initialTimingCaptured) {
  if (initialTimingCaptured) {
    const measure = performance.measure(
      'nwe_route_change',
      'nwe_route_change_start',
      'nwe_route_change_end'
    )
    return measure.duration
  } else {
    return performance.getEntriesByName('nwe_initial_load')[0].startTime
  }
}

function logTiming(initialTimingCaptured, javaScriptResourceInfo) {
  // qsperf for historical reasons (keeps Datadog action consistent)
  const metricName = initialTimingCaptured
    ? 'qsperf_route_change'
    : 'qsperf_initial_load'

  if (!initialTimingCaptured) {
    const { fileCount, resourceSize, http2Ratio } = javaScriptResourceInfo ?? {}

    if (fileCount) {
      logRumEvent('nwe_initial_load_js_file_count', {
        initialJavaScriptFileCount: fileCount,
      })
    }

    if (resourceSize) {
      logRumEvent('nwe_initial_load_js_size', {
        initialJavaScriptResourceSize: resourceSize,
      })
    }

    if (http2Ratio) {
      logRumEvent('nwe_http_2_ratio', { http2Ratio })
    }

    logPerformanceMetrics()
  }

  const data = { time: getLoadTime(initialTimingCaptured) }

  // historical names for PowerBI dashboard (and Datadog)
  logRumEvent(metricName, data)

  if (localLogging) {
    const commaFormatter = new Intl.NumberFormat('en-US')
    console.log(`${metricName}: ${commaFormatter.format(data.time)}ms`)

    if (javaScriptResourceInfo) {
      const { fileCount, resourceSize } = javaScriptResourceInfo

      console.log(`JS file count: ${fileCount}`)

      if (resourceSize) {
        console.log(`JS resource size: ${commaFormatter.format(resourceSize)}`)
      }
    }
  }
}

const MAX_WAIT_PERIOD = 60e3
const MAX_WAIT_PERIOD_WITH_SKELETON_LOADER = 300e3 // reports time out after five minutes
const WAIT_UNIT = 400
const MAJOR_DOM_SIZE_INCREASE = 1000
const MAX_CYCLES_REMAINING_AFTER_MAJOR_INCRASE = 1
const MODERATE_DOM_SIZE_INCREASE = 100
const MAX_CYCLES_REMAINING_AFTER_MODERATE_INCRASE = 3
const MINOR_DOM_SIZE_INCREASE = 25
const MAX_CYCLES_REMAINING_AFTER_MINOR_INCRASE = 6
const LIST_SKELETON_SIZE = 222

function getMaxWaitTime(
  numberOfChildren,
  lastTimeoutDuration = MAX_WAIT_PERIOD
) {
  if (numberOfChildren === LIST_SKELETON_SIZE) {
    return lastTimeoutDuration
  }

  let numberOfCyclesRemaining

  if (numberOfChildren > MAJOR_DOM_SIZE_INCREASE) {
    numberOfCyclesRemaining = MAX_CYCLES_REMAINING_AFTER_MAJOR_INCRASE
  } else if (numberOfChildren > MODERATE_DOM_SIZE_INCREASE) {
    numberOfCyclesRemaining = MAX_CYCLES_REMAINING_AFTER_MODERATE_INCRASE
  } else if (numberOfChildren > MINOR_DOM_SIZE_INCREASE) {
    numberOfCyclesRemaining = MAX_CYCLES_REMAINING_AFTER_MINOR_INCRASE
  }

  const nextTimeoutDuration = numberOfCyclesRemaining
    ? numberOfCyclesRemaining * WAIT_UNIT
    : MAX_WAIT_PERIOD

  return Math.min(nextTimeoutDuration, lastTimeoutDuration)
}

function shouldMarkOrReset(
  currentNumberOfChildren,
  numberOfChildrenInLastPageSizeIncease,
  skeletonLoadersStillLoading
) {
  const childrenChangeRatio =
    currentNumberOfChildren / numberOfChildrenInLastPageSizeIncease

  const pageSizeHasGrownSignificantly =
    childrenChangeRatio >= LCP_CHANGE_THRESHOLD
  const pageSizeHasShrunkSignificantly =
    childrenChangeRatio <= LCP_RESET_THRESHOLD
  const childCountIsSignificant =
    currentNumberOfChildren > MODERATE_DOM_SIZE_INCREASE

  let shouldMark = false
  let shouldReset = false

  if (skeletonLoadersStillLoading === true) {
    shouldReset = true
  } else if (
    skeletonLoadersStillLoading === false ||
    pageSizeHasGrownSignificantly ||
    (pageSizeHasShrunkSignificantly && childCountIsSignificant)
  ) {
    shouldMark = true
  } else if (pageSizeHasShrunkSignificantly) {
    shouldReset = true
  }

  return {
    shouldMark,
    shouldReset,
    childCountIsSignificant,
  }
}

function getSkeletonLoaders(nodeList) {
  return Array.from(nodeList).reduce((acc, node) => {
    const loaderNode = node.querySelector?.(
      '[data-testid="skeleton-loader-table-header"]'
    )
    if (loaderNode) {
      acc.add(loaderNode)
    }
    return acc
  }, new Set())
}
